<template>
  <page-view :title="title" left-arrow>
    <div class="func-list">
      <div v-for="(item,key) in funcList" :key="key" class="func-item">
        <div class="func-card" @click="itemClickHandle(item)">
          <div class="func-card__icon">
            <van-icon :name="item.icon" size="28" :style="{marginRight: '10px'}" />
          </div>
          <div class="func-card__title">{{item.title}}</div>
          <p class="func-card__text">{{item.desc}}</p>
        </div>
      </div>
    </div>
    <div style="position: fixed;bottom:0;padding: 20px;width: calc(100% - 40px);">
      <van-row>
        <van-col span="12" v-for="(item,key) in descList" @click="itemClickHandle(item)">
          <div style="text-align: center;font-size: 12px;color:#007aff;">
            <van-icon :name="item.icon" size="10" :style="{marginRight: '2px'}"/>
            <span>{{item.title}}</span>
          </div>
        </van-col>
      </van-row>
    </div>
  </page-view>
</template>

<script>
  export default {
    name: 'gfbw',
    data() {
      return {
        title: '光伏并网',
        funcList: [
          { 
            id: 'gfbwsq',
            icon: require('@/assets/icons/mobile-link.png'),
            title: '光伏并网申请',
            desc: '光伏并网申请'
          },
          { 
            id: 'gfbwjlcx',
            icon: require('@/assets/icons/mobile-link.png'),
            title: '并网记录查询',
            desc: '光伏并网申报记录查询'
          },
        ],
        descList:[
          {
            id: 'bwgzsm',
            param:{
              type:1
            },
            icon: require('@/assets/icons/mobile-link.png'),
            title: '自然人说明',
            desc: '自然人告知说明'
          },{
            id: 'bwgzsm',
            param:{
              type:0
            },
            icon: require('@/assets/icons/mobile-link.png'),
            title: '非自然人说明',
            desc: '非自然人告知说明'
          },
        ]
      }
    },
    methods: {
      itemClickHandle(item) {
        this.$router.push({
          path:item.id,
          query:item.param?item.param:{}
        });
      }
    },
  }
</script>

<style lang="scss" scoped>
  .van-col--12 {
    width: 50%;
  }
  .func-list {
    margin: 10px; 
    display: flex; 
    flex-wrap: wrap;
  }
  .func-item {
    margin-bottom: 10px; 
    padding: 0 5px; 
    flex: 1 1 50%; 
    box-sizing: border-box;
  }

  .func-card {
    border-radius: 8px;
    padding: 10px 16px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 1px 10px -5px #e2e2e2;
    &:hover,
    &:active 
    {
      background-color: #e0e0e0;
    }
  }
  .func-card__icon {
    margin-bottom: 10px;
  }
  .func-card__title {
    font-size: 18px;
    color: #4c4c4c;
    line-height: 1;
    margin-bottom: 8px;
  }

  .func-card__text {
    font-size: 12px;
    color: #999;
  }
</style>